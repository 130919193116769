import { render, staticRenderFns } from "./SignalAndMessage.vue?vue&type=template&id=6d21882d&"
import script from "./SignalAndMessage.vue?vue&type=script&lang=js&"
export * from "./SignalAndMessage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\01_ChsProjects\\71_应急物资仓库管理系统\\15.Source\\yingjiwuzi\\yudao-ui-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6d21882d')) {
      api.createRecord('6d21882d', component.options)
    } else {
      api.reload('6d21882d', component.options)
    }
    module.hot.accept("./SignalAndMessage.vue?vue&type=template&id=6d21882d&", function () {
      api.rerender('6d21882d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/bpmnProcessDesigner/package/penal/signal-message/SignalAndMessage.vue"
export default component.exports