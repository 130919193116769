import { getAccessToken } from "@/utils/auth";
import { isRelogin } from "@/utils/request";
import { Message } from "element-ui";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import router from "./router";
import store from "./store";

NProgress.configure({ showSpinner: false });

// 增加三方登陆 update by 芋艿
const whiteList = [
  "/login",
  "/social-login",
  "/auth-redirect",
  "/bind",
  "/register",
  "/oauthLogin/gitee",
];

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (getAccessToken()) {
    to.meta.title && store.dispatch("settings/setTitle", to.meta.title);
    /* has token*/
    if (to.path === "/login") {
      next({ path: "/" });
      NProgress.done();
    } else {
      if (store.getters.roles.length === 0) {
        isRelogin.show = true;
        // 获取字典数据 add by 芋艿
        store.dispatch("dict/loadDictDatas");
        // 判断当前用户是否已拉取完 user_info 信息
        store
          .dispatch("GetInfo")
          .then(() => {
            isRelogin.show = false;
            store.dispatch("GenerateRoutes").then((accessRoutes) => {
              // 根据 roles 权限生成可访问的路由表
              router.addRoutes(accessRoutes); // 动态添加可访问路由表
              next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
            });
          })
          .catch((err) => {
            store.dispatch("LogOut").then(() => {
              Message.error(err);
              next({ path: "/" });
            });
          });
      } else {
        next();
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      const redirect = encodeURIComponent(to.fullPath); // 编码 URI，保证参数跳转回去后，可以继续带上
      next(`/login?redirect=${redirect}`); // 否则全部重定向到登录页
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
